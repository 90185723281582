import React from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import { useI18n } from 'i18n';
import Avatar from 'src/components/Avatar';
import LinkMenuItem from 'src/components/LinkMenuItem';

interface Props {
  notificationsCount?: number;
}

export default function UserMenu({ notificationsCount }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useUser();
  const { t } = useI18n();

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  if (!user) return <></>;

  return (
    <>
      <IconButton onClick={openMenu} size="small">
        <Badge badgeContent={notificationsCount} color="secondary">
          <Avatar src={user.picture || undefined} alt={user.nickname || undefined} />
        </Badge>
      </IconButton>

      <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
        <LinkMenuItem href='/app/dashboard' onClick={closeMenu}>
          {t('dashboard')}
        </LinkMenuItem>
        <LinkMenuItem
          href="/api/auth/logout"
          anchor
          locale={false}
          onClick={() => {
            closeMenu();
          }}
        >
          {t('sign-out')}
        </LinkMenuItem>
      </Menu>
    </>
  );
}
